var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('ValidationProvider',{attrs:{"vid":"workflowId","name":'Flusso automation',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInputSearch',{staticStyle:{"min-width":"0px"},attrs:{"label":"Seleziona un flusso automation","value":_vm.value.workflow_id,"error":errors.length > 0,"errorMessage":errors[0],"size":"large","boundary":"viewport","placeholder":"Cerca automation per nome","options":_vm.workflows.map(function (el) {
                return {
                    label: ("" + (el.name)),
                    value: el.id,
                }
            }),"formatBadgeLabel":_vm.formatBadgeLabel,"debounce":500},on:{"input":_vm.setWorkflowId,"search":_vm.getWorkflows}})]}}],null,false,3739620766)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }