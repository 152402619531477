var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('ValidationProvider',{attrs:{"vid":"value","name":'Brand',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"label":"Seleziona un brand","value":_vm.value.value || null,"error":errors.length > 0,"errorMessage":errors[0],"size":"large","boundary":"viewport","placeholder":"Cerca brand","options":_vm.brands.map(function (el) {
                return {
                    label: el,
                    value: el,
                }
            })},on:{"input":_vm.setValue}})]}}],null,false,475925055)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }