var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"size":"large","label":"Seleziona i campi da monitorare","options":_vm.customFields.map(function (el) {
            return {
                value: el.id,
                label: el.name,
            }
        }),"value":_vm.value.custom_field_ids,"variant":"multiSelection","clearable":"","caption":"Lasciando il campo vuoto il flusso comincerà alla modifica di un campo qualsiasi"},on:{"input":_vm.setCustomFieldIds}}),(_vm.value.custom_field_ids.length > 1)?_c('VsSelect',{attrs:{"label":"Condizione","size":"large","options":[
            {
                label: 'Quando viene modificato almeno uno dei campi selezionati',
                value: 'almost_one',
            },
            {
                label: 'Quando vengono modificati tutti i campi selezionati',
                value: 'all',
            }
        ],"value":_vm.value.condition},on:{"input":_vm.setCondition}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }