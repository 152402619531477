










































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'webSite',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                campaign_id: 0,
                type: 'pageview',
                value: '',
            }
        },
    }) value!: {
        campaign_id: number
        type: 'pageview' | 'event'
        value: string
    }

    private loading = false
    $refs: any

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    setType (type: 'event' | 'pageview') {
        this.$emit('input', {
            ...this.value,
            type,
        })
    }

    setValue (value: string) {
        this.$emit('input', {
            ...this.value,
            value,
        })
    }
}
