





























import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { getStoreBrands } from '@/api/consoleApi/stores'

@Component({
    name: 'ecommerceBrandPurchased',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                value: '',
            }
        },
    }) value!: {
        value: string
    }

    @Prop({
        required: true,
        default: null,
    }) workflow!: Workflow

    private loading = false
    brands: string[] = []

    $refs: any

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    beforeMount () {
        this.getBrands()
    }

    async getBrands () {
        if (!this.workflow?.recipient) return
        try {
            const resp = await getStoreBrands(
                this.workflow?.recipient?.data?.store?.data?.id,
            )
            this.brands = resp.data
        } catch (e) {
            console.log(e)
            this.brands = []
        }
    }

    setValue (value: string) {
        this.$emit('input', {
            value: value || '',
        })
    }
}
