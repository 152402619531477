import { serviceConsole } from '@/utils/request'
import { CancelToken } from 'axios'

export const setStatus = (workflowId: any, status: 'ready' | 'pause', data?: any) =>
    serviceConsole.post(
        `/app/user/automation/setStatus/${workflowId}/${status}`,
        data,
        {
            // signal,
        },
    )
