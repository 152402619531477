

































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'ecommerceActivityHistory',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                campaign_id: 0,
                activity_type: 'click',
                value: '',
            }
        },
    }) value!: {
        campaign_id: number
        activity_type: 'click' | 'open'
        value: string
    }

    private loading = false
    $refs: any

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    setOperator (operator: 'greater' | 'smaller') {
        this.$emit('input', {
            ...this.value,
            operator,
        })
    }

    setPeriod (period: 'last_week' | 'last_month' | 'last_three_month' | 'last_six_month' | 'last_year' | 'ever') {
        this.$emit('input', {
            ...this.value,
            period,
        })
    }

    setType (type: 'amountSpentOnPeriod' | 'numberOfProductPurchasedOnPeriod' | 'numberOfOrderCompletedOnPeriod') {
        this.$emit('input', {
            ...this.value,
            type,
        })
    }

    setValue (value: string) {
        this.$emit('input', {
            ...this.value,
            value,
        })
    }
}
