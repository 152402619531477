var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"label":"Seleziona la tipologia della categoria","value":_vm.value.category_type,"size":"large","options":[
            {
                label: 'Generale',
                value: 'category',
            },
            {
                label: 'Media',
                value: 'category_med',
            },
            {
                label: 'Specifica',
                value: 'category_upper',
            } ]},on:{"input":_vm.setCategoryType}}),(_vm.value.category_type)?_c('ValidationProvider',{attrs:{"vid":"value","name":'Categoria',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"label":"Seleziona una categoria","value":_vm.value.value || null,"error":errors.length > 0,"errorMessage":errors[0],"size":"large","boundary":"viewport","placeholder":"Cerca categoria","options":_vm.categoriesOptions},on:{"input":_vm.setValue}})]}}],null,false,2865421221)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }