var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Decidi quando inviare il messaggio ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('general.close'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.workflow && _vm.message)?_c('div',[_c('ValidationObserver',{ref:"messageTriggerForm"},[_c('div',{staticClass:"vs-flex vs-gap-3 vs-items-center vs-mb-4"},[_c('VsLabel',[_vm._v(" "+_vm._s(_vm.isDateWorkflow || _vm.message.trigger.parameter.as === 'immediatily' ? 'Invia' : 'Aspetta')+" ")]),(_vm.message.trigger.parameter.as !== 'immediatily')?_c('VsInput',{staticStyle:{"max-width":"76px"},attrs:{"labelHidden":"","type":"number","variant":"number","min":1},model:{value:(_vm.message.trigger.parameter.wait),callback:function ($$v) {_vm.$set(_vm.message.trigger.parameter, "wait", $$v)},expression:"message.trigger.parameter.wait"}}):_vm._e(),_c('VsSelect',{attrs:{"labelHidden":"","boundary":"window","options":[
                        {
                            value: 'immediatily',
                            label: _vm.isDateWorkflow ? 'Lo stesso giorno' : 'Immediatamente',
                        },
                        {
                            value: 'hours',
                            label: 'Ora/e',
                        },
                        {
                            value: 'days',
                            label: 'Giorno/i',
                        } ]},model:{value:(_vm.message.trigger.parameter.as),callback:function ($$v) {_vm.$set(_vm.message.trigger.parameter, "as", $$v)},expression:"message.trigger.parameter.as"}}),(_vm.isDateWorkflow && _vm.message.trigger.parameter.as !== 'immediatily')?_c('VsSelect',{staticStyle:{"max-width":"100px"},attrs:{"labelHidden":"","boundary":"window","options":[
                        {
                            value: 'before',
                            label: 'Prima',
                        },
                        {
                            value: 'after',
                            label: 'Dopo',
                        } ]},model:{value:(_vm.message.trigger.parameter.type),callback:function ($$v) {_vm.$set(_vm.message.trigger.parameter, "type", $$v)},expression:"message.trigger.parameter.type"}}):_vm._e()],1),(_vm.isDateWorkflow)?_c('span',{staticClass:"vs-body-medium"},[_vm._v(" della data del campo "),_c('b',[_vm._v(_vm._s(_vm.workflow.settings.custom_field_id === 0 ? 'Data iscrizione' : _vm.selectedDateCustomField))])]):_vm._e(),(!_vm.isDateWorkflow && _vm.prevMessageType === 'firstTrigger')?_c('VsLabel',[_vm._v(" dopo che il contatto è entrato nella marketing automation ")]):_vm._e(),(!_vm.isDateWorkflow && _vm.prevMessageType !== 'firstTrigger')?_c('div',{staticClass:"vs-flex vs-gap-3 vs-items-center"},[_c('VsLabel',[_vm._v(" dopo che il messaggio precedente ")]),_c('ValidationProvider',{attrs:{"vid":"from","name":"Evento","rules":_vm.fromRules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsSelect',{attrs:{"labelHidden":"","boundary":"window","error":errors.length > 0,"errorMessage":errors[0],"options":_vm.fromOptions},model:{value:(_vm.message.trigger.parameter.from),callback:function ($$v) {_vm.$set(_vm.message.trigger.parameter, "from", $$v)},expression:"message.trigger.parameter.from"}})]}}],null,false,403839510)})],1):_vm._e()],1),_c('div',[_c('VsButton',{staticClass:"vs-mt-8",attrs:{"variant":"link","iconName":_vm.showAdvanced ? 'chevron-up' : 'chevron-down',"iconPosition":"right"},on:{"click":function($event){_vm.showAdvanced = !_vm.showAdvanced}}},[_vm._v(" "+_vm._s(_vm.showAdvanced ? 'Nascondi' : 'Mostra')+" avanzate ")])],1),_c('MyAccordion',{staticClass:"vs-mt-8",attrs:{"show":_vm.showAdvanced}},[_c('VsSelect',{attrs:{"label":"Giorni della settimana consentiti","placeholder":"Tutti","boundary":"window","variant":"multiSelection","options":[
                    {
                        label: 'Lunedì',
                        value: '0',
                    },
                    {
                        label: 'Martedì',
                        value: '1',
                    },
                    {
                        label: 'Mercoledì',
                        value: '2',
                    },
                    {
                        label: 'Giovedì',
                        value: '3',
                    },
                    {
                        label: 'Venerdì',
                        value: '4',
                    },
                    {
                        label: 'Sabato',
                        value: '5',
                    },
                    {
                        label: 'Domenica',
                        value: '6',
                    } ],"tooltip":"Se il flusso non dovesse verificarsi nel giorno/i selezionato/i, il messaggio verrà mandato alla prima occorrenza aggiungendo un eventuale delay all'invio."},model:{value:(_vm.parsedDays),callback:function ($$v) {_vm.parsedDays=$$v},expression:"parsedDays"}}),_c('VsCheckbox',{model:{value:(_vm.message.trigger.scheduler.active),callback:function ($$v) {_vm.$set(_vm.message.trigger.scheduler, "active", $$v)},expression:"message.trigger.scheduler.active"}},[_vm._v(" Invia solo ad un orario specifico ")]),(_vm.message.trigger.scheduler.active)?_c('div',{staticClass:"vs-mt-4 vs-flex vs-items-center vs-gap-3"},[_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"labelHidden":"","boundary":"window","options":[
                        {
                            label: '00',
                            value: '00',
                        },
                        {
                            label: '01',
                            value: '01',
                        },
                        {
                            label: '02',
                            value: '02',
                        },
                        {
                            label: '03',
                            value: '03',
                        },
                        {
                            label: '04',
                            value: '04',
                        },
                        {
                            label: '05',
                            value: '05',
                        },
                        {
                            label: '06',
                            value: '06',
                        },
                        {
                            label: '07',
                            value: '07',
                        },
                        {
                            label: '08',
                            value: '08',
                        },
                        {
                            label: '09',
                            value: '09',
                        },
                        {
                            label: '10',
                            value: '10',
                        },
                        {
                            label: '11',
                            value: '11',
                        },
                        {
                            label: '12',
                            value: '12',
                        },
                        {
                            label: '13',
                            value: '13',
                        },
                        {
                            label: '14',
                            value: '14',
                        },
                        {
                            label: '15',
                            value: '15',
                        },
                        {
                            label: '16',
                            value: '16',
                        },
                        {
                            label: '17',
                            value: '17',
                        },
                        {
                            label: '18',
                            value: '18',
                        },
                        {
                            label: '19',
                            value: '19',
                        },
                        {
                            label: '20',
                            value: '20',
                        },
                        {
                            label: '21',
                            value: '21',
                        },
                        {
                            label: '22',
                            value: '22',
                        },
                        {
                            label: '23',
                            value: '23',
                        } ]},model:{value:(_vm.message.trigger.scheduler.time.hour),callback:function ($$v) {_vm.$set(_vm.message.trigger.scheduler.time, "hour", $$v)},expression:"message.trigger.scheduler.time.hour"}}),_c('div',{staticClass:"vs-flex-none"},[_vm._v(" : ")]),_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"labelHidden":"","boundary":"window","options":[
                        {
                            label: '00',
                            value: '00',
                        },
                        {
                            label: '15',
                            value: '15',
                        },
                        {
                            label: '30',
                            value: '30',
                        },
                        {
                            label: '45',
                            value: '45',
                        } ]},model:{value:(_vm.message.trigger.scheduler.time.minute),callback:function ($$v) {_vm.$set(_vm.message.trigger.scheduler.time, "minute", $$v)},expression:"message.trigger.scheduler.time.minute"}})],1):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }