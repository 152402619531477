

































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { WorkflowsModule } from '@/store/modules/workflows'
import { getStoreProducts } from '@/api/consoleApi/stores'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
@Component({
    name: 'ecommerceProductPurchased',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                value: '',
            }
        },
    }) value!: {
        value: string
    }

    @Prop({
        required: true,
        default: null,
    }) workflow!: Workflow

    private loading = false
    products: any[] = []
    tempSelectedProduct: any = null
    $refs: any

    get selectedProduct () {
        return WorkflowsModule.selectedProduct
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    async beforeMount () {
        this.getProducts('')
    }

    async getProducts (search: string) {
        try {
            const resp = await getStoreProducts(
                this.workflow?.recipient?.data?.store?.data?.id,
                {
                    search: search.trim() !== '' ? `title:${search};description:${search}` : '',
                    searchFields: search.trim() !== '' ? 'title:like;description:like' : '',
                    searchJoin: 'or',
                    page: 1,
                    limit: 100,
                },
            )
            this.products = resp.data.data
        } catch (e) {
            console.log(e)
            this.products = []
        }
    }

    formatBadgeLabel () {
        return this.tempSelectedProduct?.title || this.selectedProduct?.title || ''
    }

    setValue (value: string) {
        this.tempSelectedProduct = this.products.find(el => el.id === value) || null
        this.$emit('input', {
            value: value || '',
        })
    }
}
