



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { WorkflowsModule } from '@/store/modules/workflows'
import { CustomField } from '@/utils/customFields'
import { getListCustomFields } from '@/api/consoleApi/recipients'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'

@Component({
    name: 'onDate',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                custom_field_id: 0,
                date_source: 'subscriptionDate',
                repeat_annually: false,
            }
        },
    }) value!: {
        custom_field_id: number
        date_source: 'subscriptionDate' | 'customField'
        repeat_annually: boolean
    }

    @Prop({
        required: true,
        default: null,
    }) workflow!: Workflow

    private loading = false
    customFields: CustomField[] = []
    tempSelectedDateCustomField: CustomField | null = null
    $refs: any

    get selectedDateCustomField () {
        return WorkflowsModule.selectedDateCustomField
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    async beforeMount () {
        this.getCustomFields('')
    }

    async getCustomFields (search: string) {
        if (!this.workflow?.recipient) return
        try {
            const resp = await getListCustomFields(
                this.workflow.recipient.data.id,
                {
                    search: `type:Date field${search.trim() !== '' ? `;name:${search}` : ''}`,
                    searchFields: `type:=${search.trim() !== '' ? ';name:like' : ''}`,
                    searchJoin: 'and',
                    page: 1,
                    limit: 100,
                },
            )
            this.customFields = resp.data.data
        } catch (e) {
            console.log(e)
            this.customFields = []
        }
    }

    formatBadgeLabel () {
        return this.tempSelectedDateCustomField?.name || this.selectedDateCustomField?.name || ''
    }

    setCustomFieldId (custom_field_id: number) {
        this.tempSelectedDateCustomField = this.customFields.find(el => el.id === custom_field_id) || null
        this.$emit('input', {
            ...this.value,
            custom_field_id: custom_field_id || 0,
        })
    }

    setDateSource (date_source: 'subscriptionDate' | 'customField') {
        this.$emit('input', {
            ...this.value,
            date_source,
        })
    }

    setRepeatAnnually (repeat_annually: boolean) {
        this.$emit('input', {
            ...this.value,
            repeat_annually,
        })
    }
}
