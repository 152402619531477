

























import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'ecommerceAbbandonedCart',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                value: 3,
            }
        },
    }) value!: {
        value: number
    }

    private loading = false
    $refs: any

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    setValue (value: string) {
        this.$emit('input', {
            value,
        })
    }
}
