































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'

@Component({
    name: 'VsWorkflowListCard',
    components: {
        VsLoader,
        VsCollapseCard,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) workflow!: Workflow
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    private loading = false
    errors: any[] = []
    $refs: any

    tabName = 'list'

    openTab () {
        this.$emit('open-tab', this.tabName)
    }

    get listName () {
        return this.workflow?.recipient?.data.name || 'Nessuna lista trovata'
    }

    get workflowListStatus () {
        return this.workflow.recipient?.data ? 'success' : 'error'
    }

    private openTabAdvanced () {
        this.openTab()
    }
}
