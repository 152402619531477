














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { getStoreCategories } from '@/api/consoleApi/stores'

@Component({
    name: 'ecommerceCategoryPurchased',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                category_type: 'category',
                value: '',
            }
        },
    }) value!: {
        category_type: 'category_upper' | 'category_med' | 'category'
        value: string
    }

    @Prop({
        required: true,
        default: null,
    }) workflow!: Workflow

    private loading = false
    categories: string[] = []

    $refs: any

    get categoriesOptions () {
        return this.categories.map((el) => {
            return {
                label: el,
                value: el,
            }
        })
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    @Watch('value.category_type', { immediate: true })
    async getCategories () {
        if (!this.workflow?.recipient) return
        try {
            const resp = await getStoreCategories(
                this.workflow?.recipient?.data?.store?.data?.id,
                this.value.category_type,
            )
            this.categories = resp.data
        } catch (e) {
            console.log(e)
            this.categories = []
        }
    }

    setCategoryType (category_type: 'category_upper' | 'category_med' | 'category') {
        this.$emit('input', {
            value: '',
            category_type,
        })
    }

    setValue (value: string) {
        this.$emit('input', {
            ...this.value,
            value: value || '',
        })
    }
}
