var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"label":"Seleziona la tipologia di campo data","value":_vm.value.date_source,"size":"large","options":[
            {
                label: 'Data iscrizione in lista',
                value: 'subscriptionDate',
            },
            {
                label: 'Campo personalizzato',
                value: 'customField',
            }
        ]},on:{"input":_vm.setDateSource}}),(_vm.value.date_source === 'customField')?_c('ValidationProvider',{attrs:{"vid":"customFieldId","name":'Campo personalizzato',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('VsInputSearch',{staticClass:"vs-mb-6",staticStyle:{"min-width":"0px"},attrs:{"label":"Seleziona un campo personalizzato","value":_vm.value.custom_field_id || null,"error":errors.length > 0,"errorMessage":errors[0],"size":"large","boundary":"viewport","placeholder":"Cerca campo di tipo data","options":_vm.customFields.map(function (el) {
                return {
                    label: el.name,
                    value: el.id,
                }
            }),"formatBadgeLabel":_vm.formatBadgeLabel,"debounce":500,"caption":'Non selezionando un campo personalizzato, il campo data utilizzato sarà quello di iscrizione alla lista'},on:{"input":_vm.setCustomFieldId,"search":_vm.getCustomFields}})]}}],null,false,2407041659)}):_vm._e(),_c('VsCheckbox',{attrs:{"size":"large","text":"Ripeti ogni anno","caption":"Se spunti questa opzione, il sistema farà ripartire la Marketing Automation ogni anno in base alle impostazioni della timeline.","value":_vm.value.repeat_annually},on:{"input":_vm.setRepeatAnnually}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }