







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { WorkflowsModule } from '@/store/modules/workflows'
import { ICampaign } from '@/api/consoleApi/dto/campaigns.dto'
import { getCampaigns } from '@/api/consoleApi/campaigns'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
@Component({
    name: 'campaignActivity',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                campaign_id: 0,
                activity_type: 'click',
                value: '',
            }
        },
    }) value!: {
        campaign_id: number
        activity_type: 'click' | 'open'
        value: string
    }

    @Prop({
        required: true,
        default: null,
    }) workflow!: Workflow

    private loading = false
    campaigns: any[] = []
    tempSelectedCampaign: ICampaign | null = null
    $refs: any

    get selectedCampaign () {
        return WorkflowsModule.selectedCampaign
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    async beforeMount () {
        this.getCampaigns('')
    }

    async getCampaigns (search: string) {
        try {
            const resp = await getCampaigns(
                {
                    search: search.trim() !== `` ? `name:${search}` : '',
                    searchFields: search.trim() !== '' ? 'name:like;' : '',
                    // scommentare se si vogliono limitare le campagne per lista del workflow
                    // search: search.trim() !== `recipients.id:${this.workflow?.recipient?.data.id || ''}` ? `recipients.id:${this.workflow?.recipient?.data.id || ''};name:${search}` : '',
                    // searchFields: search.trim() !== '' ? 'recipients.id:in;name:like;' : 'recipients.id:in',
                    searchJoin: 'and',
                    page: 1,
                    limit: 100,
                },
            )
            this.campaigns = resp.data.data
        } catch (e) {
            console.log(e)
            this.campaigns = []
        }
    }

    formatBadgeLabel () {
        return this.tempSelectedCampaign?.name || this.selectedCampaign?.name || ''
    }

    setCampaignId (campaign_id: number) {
        this.tempSelectedCampaign = this.campaigns.find(el => el.id === campaign_id) || null
        this.$emit('input', {
            ...this.value,
            campaign_id: campaign_id || null,
        })
    }

    setActivityType (activity_type: 'click' | 'open') {
        this.$emit('input', {
            ...this.value,
            activity_type,
        })
    }

    setValue (value: string) {
        this.$emit('input', {
            ...this.value,
            value,
        })
    }
}
