var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"size":"large","label":"Il contatto ha un","options":[
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.type.amountSpentOnPeriod'),
                value: 'amountSpentOnPeriod',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.type.numberOfProductPurchasedOnPeriod'),
                value: 'numberOfProductPurchasedOnPeriod',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.type.numberOfOrderCompletedOnPeriod'),
                value: 'numberOfOrderCompletedOnPeriod',
            } ],"value":_vm.value.type},on:{"input":_vm.setType}}),_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"size":"large","label":"Operatore","options":[
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.operator.greater'),
                value: 'greater',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.operator.smaller'),
                value: 'smaller',
            } ],"value":_vm.value.operator},on:{"input":_vm.setOperator}}),_c('ValidationProvider',{attrs:{"vid":"value","name":'Quantità',"slim":"","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-6",attrs:{"label":"Quantità","size":"large","type":"number","variant":"number","error":errors.length > 0,"errorMessage":errors[0],"min":"1","value":_vm.value.value},on:{"input":_vm.setValue}})]}}],null,false,3377639343)}),_c('VsSelect',{attrs:{"size":"large","label":"Periodo","options":[
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.period.last_week'),
                value: 'last_week',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.period.last_month'),
                value: 'last_month',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.period.last_three_month'),
                value: 'last_three_month',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.period.last_six_month'),
                value: 'last_six_month',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.period.last_year'),
                value: 'last_year',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.period.ever'),
                value: 'ever',
            } ],"value":_vm.value.period},on:{"input":_vm.setPeriod}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }