


















































































































































import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import { UserModule } from '@/store/modules/user'
import {
    IMessageSms,
} from '@/api/consoleApi/dto/campaigns.dto'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsLink from '@advision/vision/src/components/utilities/VsLink/Index.vue'
import { getSendersSms } from '@/api/consoleApi/senders/sms'

@Component({
    name: 'VsWorkflowMessageSmsCard',
    components: {
        VsListingCard,
        VsLink,
        VsDropdownButton,
        VsImage,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private sms!: IMessageSms

    @Prop({
        default: true,
        required: false,
        type: Boolean,
    }) private isEditable!: boolean

    footerPlain = ''
    footerHtml = ''
    selectedSender: any = null

    get messageId () {
        return this.sms.id
    }

    @Watch('hasError', { immediate: true })
    emitErrors (val: boolean) {
        this.$emit('errors', val)
    }

    get missingSteps () {
        const missing = []

        if (this.smsContentStatus === 'error') missing.push('content')
        if (this.totalSenderStatus === 'error') missing.push('sender')

        return missing
    }

    get hasError () {
        return this.isEditable && (this.smsContentStatus === 'error' || this.totalSenderStatus === 'error')
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canExportHtml () {
        return this.userRules.exportHtml
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get defaultImage () {
        return require('@/assets/img/campaigns/sms-placeholder.png')
    }

    get previewImage () {
        return this.defaultImage
    }

    get contentError () {
        return ''
    }

    get smsContentStatus () {
        if (!this.sms.body) return 'error'
        return 'success'
    }

    get senderEnabledStatus () {
        return this.selectedSender?.status === 'Enabled' ? 'success' : 'error'
    }

    get senderConfirmed () {
        return this.selectedSender?.confirmed ? 'success' : 'error'
    }

    get totalSenderStatus () {
        if (
            this.senderEnabledStatus === 'error' || this.senderConfirmed === 'error'
        ) return 'error'
        return 'success'
    }

    @Watch('sms.from', { immediate: true, deep: true })
    private async getCurrentSender (senderSms?: string) {
        const sender = senderSms || this.sms.from
        if (!sender) return
        try {
            const resp = await getSendersSms({
                limit: 1,
                search: `alias:${sender}`,
                searchFields: 'alias:=',
            })
            this.selectedSender = resp.data.data[0]
        } catch (e) {
            this.selectedSender = null
            console.log(e)
        }
    }
}
