






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { getListCustomFields } from '@/api/consoleApi/recipients'
import { CustomField } from '@/utils/customFields'
@Component({
    name: 'updateSubscriber',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                custom_field_ids: [],
                condition: 'almost_one',
            }
        },
    }) value!: {
        custom_field_ids: number[]
        condition: 'almost_one' | 'all'
    }

    @Prop({
        required: true,
        default: null,
    }) workflow!: any

    private loading = false
    customFields: CustomField[] = []
    tempSelectedDateCustomFields: CustomField[] = []

    $refs: any

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    @Watch('workflow.recipient.data.id', { immediate: true, deep: true })
    async getCustomFields () {
        if (!this.workflow?.recipient?.data?.id) return
        try {
            const cf: any[] = []
            await this.loopCustomFieldsCall(1, cf)
            this.customFields = cf
        } catch (e) {
            console.log(e)
            this.customFields = []
        }
    }

    async loopCustomFieldsCall (page: number, values: any[]) {
        const resp = await getListCustomFields(this.workflow?.recipient?.data?.id || '', {
            page,
            limit: 100,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCustomFieldsCall(resp.data.meta.pagination.current_page + 1, values)
        }
    }

    setCondition (condition: 'almost_one' | 'all') {
        this.$emit('input', {
            ...this.value,
            condition,
        })
    }

    setCustomFieldIds (custom_field_ids: number[]) {
        this.$emit('input', {
            ...this.value,
            custom_field_ids,
        })
    }
}
