var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('ValidationProvider',{attrs:{"vid":"value","name":'Prodotto',"slim":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInputSearch',{staticStyle:{"min-width":"0px"},attrs:{"label":"Seleziona un prodotto","value":_vm.value.value,"error":errors.length > 0,"errorMessage":errors[0],"size":"large","boundary":"viewport","placeholder":"Cerca prodotto per nome","options":_vm.products.map(function (el) {
                return {
                    label: ("" + (el.title)),
                    value: el.id,
                }
            }),"formatBadgeLabel":_vm.formatBadgeLabel,"debounce":500,"caption":"Lasciando il campo vuoto il flusso partirà quando verrà acquistato un prodotto qualsiasi"},on:{"input":_vm.setValue,"search":_vm.getProducts}})]}}],null,false,2951522014)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }