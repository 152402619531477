var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"label":"Seleziona l'evento che fa partire il flusso","size":"large","options":[
            {
                label: 'Un contatto visita una pagina del mio sito',
                value: 'pageview',
            },
            {
                label: 'Un contatto scatena un evento specifico del mio sito',
                value: 'event',
            } ],"value":_vm.value.type},on:{"input":_vm.setType}}),_c('ValidationProvider',{attrs:{"vid":"value","name":_vm.value.type === 'pageview' ? 'Url della pagina' : 'Nome dell\'evento',"slim":"","rules":{
            required: true,
            link: _vm.value.type === 'pageview' ? 'requiredProtocol' : false
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('VsInput',{attrs:{"label":_vm.value.type === 'pageview' ? 'Inserisci l\'url della pagina' : 'Inserisci il nome dell\'evento',"clearable":"","size":"large","error":errors.length > 0,"errorMessage":errors[0],"value":_vm.value.value},on:{"input":_vm.setValue}})]}}],null,false,73427604)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }