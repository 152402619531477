
































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { WorkflowsModule } from '@/store/modules/workflows'
import { getWorkflows } from '@/api/consoleApi/workflows'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
@Component({
    name: 'flowEnd',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                custom_field_ids: [],
                condition: 'almost_one',
            }
        },
    }) value!: {
        custom_field_ids: number[]
        condition: 'almost_one' | 'all'
    }

    private loading = false
    workflows: any[] = []
    tempSelectedWorkflow: Workflow | null = null
    $refs: any

    get selectedWorkflow () {
        return WorkflowsModule.selectedWorkflow
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    async beforeMount () {
        this.getWorkflows('')
    }

    async getWorkflows (search: string) {
        try {
            const resp = await getWorkflows(
                {
                    search: search.trim() !== '' ? `name:${search}` : '',
                    searchFields: search.trim() !== '' ? 'name:like' : '',
                    searchJoin: 'and',
                    page: 1,
                    limit: 100,
                },
            )
            this.workflows = resp.data.data
        } catch (e) {
            console.log(e)
            this.workflows = []
        }
    }

    formatBadgeLabel () {
        return this.tempSelectedWorkflow?.name || this.selectedWorkflow?.name || ''
    }

    setWorkflowId (workflow_id: number) {
        this.tempSelectedWorkflow = this.workflows.find(el => el.id === workflow_id) || null
        this.$emit('input', {
            workflow_id,
        })
    }
}
