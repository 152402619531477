














































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import { WorkflowMessage, Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { updateWorkflowMessage } from '@/api/consoleApi/workflows'
import { WorkflowsModule } from '@/store/modules/workflows'

@Component({
    name: 'VsMessageTriggerModal',
    components: {
        MyAccordion,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    workflow: Workflow | null = null
    message: WorkflowMessage | null = null
    prevMessage: WorkflowMessage | null = null
    showAdvanced = false
    $refs: any

    get isDateWorkflow () {
        return this.workflow?.trigger_name === 'onDate'
    }

    get parsedDays () {
        if (!this.message) return []
        return this.message.trigger.scheduler.days.map((el: number) => el + '')
    }

    set parsedDays (val: string[]) {
        if (!this.message) return
        this.message.trigger.scheduler.days = val.map((el: string) => parseInt(el))
    }

    get selectedDateCustomField () {
        return WorkflowsModule.selectedDateCustomField?.name || ''
    }

    get availableSmsFrom () {
        return [
            'sent',
            'delivered',
            'failed',
            'clicked',
            'notclicked',
        ]
    }

    get availableEmailFrom () {
        return [
            'sent',
            'opened',
            'clicked',
            'notopened',
            'notclicked',
        ]
    }

    get fromRules () {
        return {
            required: true,
            oneOf: this.prevMessageType === 'sms' ? this.availableSmsFrom : this.availableEmailFrom,
        }
    }

    get prevMessageType () {
        if (!this.workflow || !this.message) return
        if (this.isDateWorkflow || (!this.isDateWorkflow && this.message.priority === 1)) {
            return 'firstTrigger'
        }

        if (this.prevMessage && this.prevMessage.entity_type === 'sms') {
            return 'sms'
        }

        if (this.prevMessage && this.prevMessage.entity_type === 'email') {
            return 'email'
        }

        return 'firstTrigger'
    }

    get fromOptions () {
        return this.prevMessageType === 'sms' ? [
            {
                value: 'sent',
                label: 'è stato inviato',
            },
            {
                value: 'delivered',
                label: 'è stato consegnato',
            },
            {
                value: 'failed',
                label: 'è fallito',
            },
            {
                value: 'clicked',
                label: 'è stato cliccato',
            },
            {
                value: 'notclicked',
                label: 'non è stato cliccato',
            },
        ] : [
            {
                value: 'sent',
                label: 'è stato inviato',
            },
            {
                value: 'opened',
                label: 'è stato aperto',
            },
            {
                value: 'clicked',
                label: 'è stato cliccato',
            },
            {
                value: 'notopened',
                label: 'non è stato aperto',
            },
            {
                value: 'notclicked',
                label: 'non è stato cliccato',
            },
        ]
    }

    public async openModal (workflow: Workflow, message: WorkflowMessage) {
        this.workflow = JSON.parse(JSON.stringify(workflow))
        this.message = JSON.parse(JSON.stringify(message))
        if (!this.workflow || !this.workflow.messages) return
        if (message.trigger.parameter.as === 'months') {
            message.trigger.parameter.as = 'days'
            message.trigger.parameter.wait = message.trigger.parameter.wait * 168
        }
        this.prevMessage = null
        if (!this.isDateWorkflow || this.message?.priority === 1) {
            this.prevMessage = this.workflow.messages.data.find((el: WorkflowMessage) => this.message && el.priority === this.message.priority - 1) || null
        }
        this.show = true
    }

    closeModal () {
        this.show = false
    }

    async save () {
        if (!this.message) return
        const valid = await this.$refs.messageTriggerForm.validate()
        if (!valid) return
        this.loading = true
        try {
            if (this.message.trigger.scheduler.days.length === 0) {
                this.message.trigger.scheduler.days = [0, 1, 2, 3, 4, 5, 6]
            }
            await updateWorkflowMessage(this.workflow?.id || '', this.message?.id, this.message)
            this.$emit('save')
            this.closeModal()
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }
}
