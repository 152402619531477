var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('ValidationProvider',{attrs:{"vid":"campaignId","name":'Campagna',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInputSearch',{staticClass:"vs-mb-6",staticStyle:{"min-width":"0px"},attrs:{"label":"Seleziona una campagna","value":_vm.value.campaign_id || null,"error":errors.length > 0,"errorMessage":errors[0],"size":"large","boundary":"viewport","placeholder":"Cerca campagna per nome","options":_vm.campaigns.map(function (el) {
                return {
                    label: el.name,
                    value: el.id,
                }
            }),"formatBadgeLabel":_vm.formatBadgeLabel,"debounce":500},on:{"input":_vm.setCampaignId,"search":_vm.getCampaigns}})]}}],null,false,903910634)}),_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"size":"large","label":"Seleziona l'evento che fa partire il flusso","options":[
            {
                label: 'Click',
                value: 'click',
            },
            {
                label: 'Apertura',
                value: 'open',
            } ],"value":_vm.value.activity_type},on:{"input":_vm.setActivityType}}),(_vm.value.activity_type === 'click')?_c('ValidationProvider',{attrs:{"vid":"clickUrl","name":'Link',"slim":"","rules":"link:requiredProtocol"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('VsInput',{attrs:{"label":"Inserisci un link specifico","size":"large","clearable":"","error":errors.length > 0,"errorMessage":errors[0],"value":_vm.value.value,"caption":"Se lasciato vuoto il flusso partirà quando un link qualsiasi viene cliccato"},on:{"input":_vm.setValue}})]}}],null,false,97576577)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }