








































































































































































import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import { UserModule } from '@/store/modules/user'
import {
    IMessageEmail,
} from '@/api/consoleApi/dto/campaigns.dto'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsLink from '@advision/vision/src/components/utilities/VsLink/Index.vue'
import { contentContainsConfirmationLink, contentContainsUnsubscriptionLink, contentHasCsaTags, contentHasPlaceHolderImages } from '@/utils/personalizations'
import { getSendersEmail } from '@/api/consoleApi/senders/emails'

@Component({
    name: 'VsWorkflowMessageEmailCard',
    components: {
        VsListingCard,
        VsLink,
        VsDropdownButton,
        VsImage,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private email!: IMessageEmail

    @Prop({
        default: true,
        required: false,
        type: Boolean,
    }) private isEditable!: boolean

    selectedSender: any = null

    get messageId () {
        return this.email.id
    }

    @Watch('hasError', { immediate: true })
    emitErrors (val: boolean) {
        this.$emit('errors', val)
    }

    get missingSteps () {
        const missing = []

        if (this.emailContentStatus === 'error') missing.push('content')
        if (this.subjectError) missing.push('subject')
        if (this.senderCardStatus === 'error') missing.push('sender')

        return missing
    }

    get hasError () {
        return this.isEditable && (this.emailContentStatus === 'error' || this.subjectError || this.senderCardStatus === 'error')
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canExportHtml () {
        return this.userRules.exportHtml
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get campaignType () {
        if (this.email.project_id) return 'Drago'
        return 'Html'
    }

    get defaultImage () {
        return require('@/assets/img/campaigns/email-placeholder.png')
    }

    get previewImage () {
        return this.email.thumbnail_url ? this.email.thumbnail_url + `?t=${Date.now()}` : this.defaultImage
    }

    get subjectError () {
        return !this.email?.subject
    }

    get contentError () {
        return ''
    }

    get emailContentStatus () {
        if (!this.email.html_content) return 'error'
        if (
            this.plainContentStatus === 'error' ||
            this.hasUnsubscribeLinkStatus === 'error' ||
            this.hasPlaceHolderImageStatus === 'error' ||
            this.hasCsaTags === 'error' ||
            this.hasPlainContentCsaTags === 'error'
        ) return 'error'
        return 'success'
    }

    get plainContentStatus () {
        return this.hasPlainContentStatus === 'error' ||
            this.hasPlainContentUnsubscribeLinkStatus === 'error' ||
            this.hasPlainContentCsaTags === 'error'
            ? 'error' : 'success'
    }

    get hasPlainContentStatus () {
        return this.email.plain_content?.trim() ? 'success' : 'error'
    }

    get hasPlainContentUnsubscribeLinkStatus () {
        return contentContainsUnsubscriptionLink(this.email.plain_content?.trim()) ||
            contentContainsUnsubscriptionLink((this.email.footer?.data.plain || '').trim()) ? 'success' : 'error'
    }

    get hasPlainContentCsaTags () {
        return contentHasCsaTags(this.email.plain_content?.trim()) ||
            contentHasCsaTags((this.email.footer?.data.plain || '').trim()) ? 'success' : 'error'
    }

    get hasPlainConfirmationLinkStatus () {
        return contentContainsConfirmationLink(this.email.plain_content?.trim()) ||
            contentContainsConfirmationLink((this.email.footer?.data.plain || '').trim()) ? 'success' : 'error'
    }

    get hasUnsubscribeLinkStatus () {
        return contentContainsUnsubscriptionLink(this.email.html_content) ||
            contentContainsUnsubscriptionLink(this.email.footer?.data.html || '') ? 'success' : 'error'
    }

    get hasConfirmationLinkStatus () {
        return contentContainsConfirmationLink(this.email.html_content) ||
            contentContainsConfirmationLink(this.email.footer?.data.html || '') ? 'success' : 'error'
    }

    get hasPlaceHolderImageStatus () {
        return contentHasPlaceHolderImages(this.email.html_content) ? 'error' : 'success'
    }

    get hasCsaTags () {
        return contentHasCsaTags(this.email.html_content) ||
            contentHasCsaTags(this.email.footer?.data.html || '') ? 'success' : 'error'
    }

    get senderCardStatus () {
        if (!this.email.from_email) return 'error'
        return this.selectedSender && this.selectedSender.status === 'Enabled' ? 'success' : 'error'
    }

    @Watch('email.from_email', { immediate: true, deep: true })
    private async getCurrentSender (senderEmail?: string) {
        const sender = senderEmail || this.email.from_email
        if (!sender) return
        try {
            const resp = await getSendersEmail({
                limit: 1,
                search: `email:${sender}`,
                searchFields: 'email:like',
            })
            this.selectedSender = resp.data.data[0]
        } catch (e) {
            this.selectedSender = null
            console.log(e)
        }
    }
}
