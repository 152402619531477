import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { CustomField } from '@/utils/customFields'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { ICampaign } from '@/api/consoleApi/dto/campaigns.dto'

export interface IWorkflowsState {
    selectedCustomFields: CustomField[]
    selectedCustomField: CustomField | null
    selectedWorkflow: Workflow | null
    selectedCampaign: ICampaign | null
    selectedProduct: any
}

@Module({ dynamic: true, store, name: 'workflows', namespaced: true })
class Workflows extends VuexModule implements IWorkflowsState {
    public selectedCustomFields: CustomField[] = []
    public selectedCustomField: CustomField | null = null
    public selectedDateCustomField: CustomField | null = null
    public selectedWorkflow: Workflow | null = null
    public selectedCampaign: ICampaign | null = null
    public selectedForm: any = null
    public selectedProduct: any = null

    @Mutation
    public SET_SELECTED_CUSTOM_FIELDS (selectedCustomFields: CustomField[]) {
        this.selectedCustomFields = selectedCustomFields
    }

    @Mutation
    public SET_SELECTED_CUSTOM_FIELD (selectedCustomField: CustomField | null) {
        this.selectedCustomField = selectedCustomField
    }

    @Mutation
    public SET_SELECTED_DATE_CUSTOM_FIELD (selectedDateCustomField: CustomField | null) {
        this.selectedDateCustomField = selectedDateCustomField
    }

    @Mutation
    public SET_SELECTED_WORKFLOW (selectedWorkflow: Workflow | null) {
        this.selectedWorkflow = selectedWorkflow
    }

    @Mutation
    public SET_SELECTED_CAMPAIGN (selectedCampaign: ICampaign | null) {
        this.selectedCampaign = selectedCampaign
    }

    @Mutation
    public SET_SELECTED_FORM (selectedForm: any) {
        this.selectedForm = selectedForm
    }

    @Mutation
    public SET_SELECTED_PRODUCT (selectedProduct: any) {
        this.selectedProduct = selectedProduct
    }
}

export const WorkflowsModule = getModule(Workflows)
