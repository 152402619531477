
































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getListSegments } from '@/api/consoleApi/recipients'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import { WorkflowMessage, Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { updateWorkflowMessage } from '@/api/consoleApi/workflows'

@Component({
    name: 'VsMessageSegmentsModal',
    components: {
        VsActionCard,
    },
})
export default class extends Vue {
    private total = 0
    private listFilters: any[] = []

    private show = false
    private loading = false
    workflow: Workflow | null = null
    message: WorkflowMessage | null = null
    prevMessage: WorkflowMessage | null = null
    $refs: any

    get value () {
        if (!this.message) return []
        return this.message.trigger.segments
    }

    set value (val) {
        if (!this.message) return
        this.message.trigger.segments = val
    }

    get listId () {
        if (!this.workflow) return 0
        return this.workflow?.recipient_id || 0
    }

    get isDateWorkflow () {
        return this.workflow?.trigger_name === 'onDate'
    }

    get prevMessageType () {
        if (!this.workflow || !this.message) return
        if (this.isDateWorkflow || (!this.isDateWorkflow && this.message.priority === 1)) {
            return 'firstTrigger'
        }

        if (this.prevMessage && this.prevMessage.entity_type === 'sms') {
            return 'sms'
        }

        if (this.prevMessage && this.prevMessage.entity_type === 'email') {
            return 'email'
        }

        return 'firstTrigger'
    }

    public async openModal (workflow: any, message: any) {
        this.workflow = JSON.parse(JSON.stringify(workflow))
        this.message = JSON.parse(JSON.stringify(message))
        if (!this.workflow || !this.workflow?.messages) return
        this.prevMessage = this.workflow.messages.data.find((el: WorkflowMessage) => this.message && el.priority === this.message.priority - 1) || null
        this.show = true
        this.getListFilters()
    }

    closeModal () {
        this.show = false
    }

    async save () {
        this.loading = true
        try {
            await updateWorkflowMessage(this.workflow?.id || '', this.message?.id || 0, this.message)
            this.$emit('save')
            this.closeModal()
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    get profilationsOrderBy () {
        return [
            {
                label: this.$t('general.orderBy.createdAtDesc'),
                value: 'created_at|desc',
            },
            {
                label: this.$t('general.orderBy.createdAtAsc'),
                value: 'created_at|asc',
            },
        ]
    }

    private filters = {
        search: '',
    }

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.pagination.page = 1
        this.getListFilters()
    }

    @Watch('pagination', { deep: true, immediate: false })
    async getListFilters () {
        try {
            const response = await getListSegments(
                this.listId,
                this.buildParams(),
            )
            this.listFilters = response.data.data
            this.total = response.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.listFilters = []
            this.total = 0
        }
    }

    private buildParams () {
        const params: any = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.search.trim() !== '' ? `name:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.search.trim() !== '' ? 'name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    private isChecked (filter: any) {
        return !!this.value.find(el => parseInt(el.id) === filter.id)
    }

    private toggleSelection (filter: any) {
        const index = this.value.findIndex(el => parseInt(el.id) === filter.id)
        const newValue = [
            ...this.value,
        ]
        if (index === -1) {
            newValue.push({
                id: filter.id + '',
                name: filter.name,
            })
        } else {
            newValue.splice(index, 1)
        }
        this.value = newValue
    }
}
