var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',[_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"size":"large","label":"Ha concluso un ordine","options":[
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.operator.greater'),
                value: 'greater',
            },
            {
                label: _vm.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.operator.smaller'),
                value: 'smaller',
            } ],"value":_vm.value.operator},on:{"input":_vm.setOperator}}),_c('ValidationProvider',{attrs:{"vid":"value","name":'Prezzo totale',"slim":"","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-6",attrs:{"label":"Prezzo totale","size":"large","type":"number","variant":"number","error":errors.length > 0,"errorMessage":errors[0],"min":"0","value":_vm.value.value},on:{"input":_vm.setValue}})]}}],null,false,4264366883)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }